:root {
  /* Sizes */
  --page-max-width: 1120px;
  --page-horizontal-padding: 16px;
  --page-left-padding: var(--page-horizontal-padding);
  --page-left-padding: calc(
    var(--page-horizontal-padding) + env(safe-area-inset-left)
  );
  --page-right-padding: var(--page-horizontal-padding);
  --page-right-padding: calc(
    var(--page-horizontal-padding) + env(safe-area-inset-right)
  );
  --section-gap: 58px;
  --header-height: 80px;
  --footer-height: 360px;
  --landing-testimonials-gap: 24px;

  /* Font */
  --font-family-title: var(--font-be-vietnam), sans-serif;
  --font-family-text: var(--font-be-vietnam), sans-serif;
  --font-family-quote: var(--font-benne), sans-serif;
  --heading-font-size: 36px;

  /* Z-indexes */
  --z-index-header: 10;
  --z-index-mobile-menu: 20;
  --z-index-mobile-menu-button: 30;
  --z-index-dialog-overlay: 40;
  --z-index-dialog-content: 50;

  /* Spacings */
  --spacing-56: 56px;

  /* Colors */
  --white: #fff;
  --white-rgb: 255, 255, 255;
  --black: #000;
  --black-rgb: 0, 0, 0;
  --light: #fefefe;
  --light-rgb: 254, 254, 254;
  --dark: #050202;
  --dark-rgb: 5, 2, 2;
  --grey-600: #393939;
  --grey-600-rgba: rgb(26, 26, 26);
  --grey-100: #f3f4f8;
  --theme-black: #000;
  --primary-500: #ff5c57;
  --primary-500-rgb: 251, 112, 107;
  --primary-400: #ff6f6a;
  --primary-300: #f18480;
  --primary-200: #e9aba8;
  --pink-200: #fb706b;
  --blue-rgb: 121, 166, 255;
  --blue: #79a6ff;
  --green-rgb: 92, 244, 89;
  --green: #5cf459;
}

@media (--tablet) {
  :root {
    /* Font */
    --heading-font-size: 32px;
  }
}

@media (--mobile) {
  :root {
    /* Sizes */
    --header-height: 72px;
    --footer-height: 936px;
    --landing-testimonials-gap: 16px;
    --section-gap: 32px;

    /* Font */
    --heading-font-size: 24px;

    /* Spacings */
    --spacing-56: 32px;
  }
}
